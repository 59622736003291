/**
 * Submit language select form on change.
 */
function setupLanguageSelector(): void {
  const langSelector: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    'form#language-selector input[name=language]'
  )

  langSelector.forEach((el): void => {
    el.onclick = () => el.closest('form')!.submit();
  })
}

/**
 * Syncs compliance level slider and 'goal' number field
 */
function setupTargetCalculator(): void {
  const avgVisits: HTMLInputElement | null = document.querySelector('input#id_enrollment-average_visits')
  const compSlider: HTMLInputElement | null = document.querySelector('input#id_enrollment-compliance_level')
  const goal: HTMLInputElement | null = document.querySelector('input#id_enrollment-goal')
  const sliderHelper: HTMLElement | null = document.querySelector('p#id_slider_helper span')

  // All fields must be present to go on.
  if (avgVisits === null || compSlider === null || goal === null || sliderHelper === null) {
    return
  }

  // Changing averageVisits changes the goal (with slider value)
  // and sets max values on goal
  avgVisits.onkeyup = (): void => {
    goal.max = avgVisits.value
    goal.value = Math.round(avgVisits.valueAsNumber * compSlider.valueAsNumber / 100).toString()
  }

  // Changing the slider changes the goal
  compSlider.oninput = (): void => {
    goal.value = Math.round(avgVisits.valueAsNumber * compSlider.valueAsNumber / 100).toString()
    sliderHelper.textContent = `${compSlider.valueAsNumber}%`
    sliderHelper.style.marginLeft = `${compSlider.valueAsNumber}%`
  }

  // Changing the goal, changes the slider
  goal.onkeyup = (): void => {
    compSlider.value = (goal.valueAsNumber * 100 / avgVisits.valueAsNumber).toString()
    sliderHelper.textContent = `${compSlider.valueAsNumber}%`
    sliderHelper.style.marginLeft = `${compSlider.valueAsNumber}%`
  }
}

// Setup on page load
document.addEventListener('DOMContentLoaded', (): void => {
  setupLanguageSelector()
  setupTargetCalculator()
})
